import { render, staticRenderFns } from "./layout2.vue?vue&type=template&id=784bf437&scoped=true&"
import script from "./layout2.vue?vue&type=script&lang=ts&"
export * from "./layout2.vue?vue&type=script&lang=ts&"
import style0 from "./layout2.vue?vue&type=style&index=0&id=784bf437&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784bf437",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer2: require('/root/workspace/com.jufair.vue_Z6yh/components/Footer2/index.vue').default})
